.card {
    box-shadow: 0 0.5vw 0.75vh 0 rgba(0, 0, 0, 0.2);
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); */
    max-width: 27.5vw;
    margin: auto;
    text-align: center;
    font-family: arial;
    background-color: white;
    color:black;
    box-shadow: 1.5vw 1.5vw 1.5vw rgb(75, 237, 11);

    /* box-shadow: 5px 5px 5px black; */
    
    border-radius:1.5vw 1.5vw 1.5vw 1.5vw;

    /* border-radius:15px 15px 15px 15px; */

}
  
.price {
    /* color: grey; */
    font-size: 1.5vw;
    padding-right: 0.5vw;
    padding-left: 0.5vw;
    
}
  
.card button {
    border: none;
    outline: 0;
    padding: 1.5vh;
    color: black;
    /* background-color: #000; */
    text-align: center;
    cursor: pointer;
    width: 17.5vw;
    font-size: 1.5vw;

    /* font-size: 18px; */
    border-radius: 1.5vw;
}



h2{
    font-size: 2.1vw;
    padding-top: 2.5vh;
}
  
/* .card button:hover { */
    /* opacity: 0.7; */
/* } */