ul {
    position: relative;
    list-style-type: none;
    padding: 0;
    margin:0;
    overflow: hidden;
    background-color: #333333;
  }
  
  li {
    float: left;
    color:white;
    padding-top: 5vh;
    padding-bottom: 5vh;
    padding-left: 4.5vw;
    font-size: 3.5vh;
    /* height: 10vh; */
  }
  
  li a {
    display: block;
    color: white;
    text-align: center;
    padding: 16px;
    text-decoration: none;
  }
  
  li a:hover {
    background-color: #111111;
  }

